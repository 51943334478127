<template>
    <div class="main-container">

        <div class="top-menu">
            <div class="top-menu__item" :class="{ active: currentSlide === 0 }" @click="slideTo(0)">Monitoring</div>
            <div class="top-menu__item" :class="{ active: currentSlide === 1 }" @click="slideTo(1)">Solutions</div>
            <div class="top-menu__item" :class="{ active: currentSlide === 2 }" @click="slideTo(2)">New features</div>
            <div class="top-menu__item" :class="{ active: currentSlide === 3 }" @click="slideTo(3)">New products</div>
            <div class="top-menu__item" :class="{ active: currentSlide === 4 }" @click="slideTo(4)">Battery compatibility</div>
            <div class="top-menu__item" :class="{ active: currentSlide === 5 }" @click="slideTo(5)">Why Victron Energy</div>
        </div>

        <img :src="'./media/images/logo.svg'" class="main-logo" alt="logo">

        <a href="index.html" class="top-switch">
            <img :src="'./media/images/offgrid/icon-marine.svg'" class="top-switch-marine-icon" alt="go to marine">
        </a>

        <div class="top-button block"
            @click.prevent="openPopup($event, 'image', './media/images/offgrid/where-to-buy/where-to-buy.webp', 'light' )">
            <img :src="'./media/images/button-where-to-buy.svg'" alt="Wehre to buy">
        </div>

        <div class="swiper mySwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide slide0">
                    <div class="slide0__start-block block">
                         <img :src="'./media/images/offgrid/slide0/slide0.webp'" alt="">
                        <div class="click-block-offgrid-1" @click.prevent="openPopup($event, 'image', './media/images/offgrid/monitoring/monitoring-victron-connect.webp', 'light')"></div>
                        <div class="click-block-offgrid-2" @click.prevent="openPopup($event, 'image-switch', vrmSlides, 'light', 1)"></div>
                        <div class="click-block-offgrid-3"  @click.prevent="openPopup($event, 'image', './media/images/offgrid/monitoring/monitoring-venus-os.webp', 'light')"></div>
                        <div class="click-block-offgrid-4"  @click.prevent="openPopup($event, 'image', './media/images/offgrid/monitoring/monitoring-mfd.webp', 'light')"></div>
                    </div>
                </div>

                <div class="swiper-slide slide1">
                    
                    <div class="slide1-marine__intro block">
                        <img :src="'./media/images/offgrid/slide1/slide1__intro.webp'" alt="">
                    </div>

                    <div class="slide1__left">
                        
                        <div class="slide1__backup-block block"
                            @click.prevent="openPopup($event, 'slider', systemSlides, 'dark', 0 )">
                            <img :src="'./media/images/offgrid/slide1/slide1__backup.webp'" alt="">
                        </div>
                        <div class="slide1__energy-block block"
                            @click.prevent="openPopup($event, 'slider', systemSlides, 'dark', 1 )">
                            <img :src="'./media/images/offgrid/slide1/slide1__energy.webp'" alt="">
                        </div>

                    </div>
                    <div class="slide1__right">
                        <div class="slide1__hybrid-generators block"
                            @click.prevent="openPopup($event, 'slider', systemSlides, 'dark', 4 )">
                            <img :src="'./media/images/offgrid/slide1/slide1__hybrid-generators.webp'" alt="">
                        </div>
                        <div class="slide1__block-bottom">
                            <div class="slide1__backup-block block"
                                @click.prevent="openPopup($event, 'slider', systemSlides, 'dark', 2 )">
                                <img :src="'./media/images/offgrid/slide1/slide1__offgrid.webp'" alt="">
                            </div>
                            <div class="slide1__energy-block block"
                                @click.prevent="openPopup($event, 'slider', systemSlides, 'dark', 3 )">
                                <img :src="'./media/images/offgrid/slide1/slide1__minigrid.webp'" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide slide-new-features">
                    <div class="slide-new-features__left block"
                        @click.prevent="openPopup($event, 'image', './media/images/offgrid/new-features/new-features-slide1.webp', 'light')">
                        <img :src="'./media/images/offgrid/slide-new-features/slide-new-features-block-1.webp'" alt="">
                    </div>
                    <div class="slide-new-features__right">
                        <div class="slide-new-features__product2-block block"
                            @click.prevent="openPopup($event, 'image', './media/images/offgrid/new-features/new-features-slide2.webp', 'light')">
                            <img :src="'./media/images/offgrid/slide-new-features/slide-new-features-block-2.webp'" alt="">
                        </div>
                        <div class="slide-new-features__product3-block block"
                            @click.prevent="openPopup($event, 'image', './media/images/offgrid/new-features/new-features-slide3.webp', 'light')">
                            <img :src="'./media/images/offgrid/slide-new-features/slide-new-features-block-3.webp'" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide slide3">
                    <div class="slide3__left block"
                        @click.prevent="openPopup($event, 'slider', productSlides, 'light', 0 )">
                        <img :src="'./media/images/offgrid/slide3/slide3__product1.webp'" alt="">
                    </div>
                    <div class="slide3__right">
                        <div class="slide3__product2-block block"
                            @click.prevent="openPopup($event, 'slider', productSlides, 'light', 1 )">
                            <img :src="'./media/images/offgrid/slide3/slide3__product2.webp'" alt="">
                        </div>
                        <div class="slide3__product3-block block"
                            @click.prevent="openPopup($event, 'slider', productSlides, 'light', 2 )">
                            <img :src="'./media/images/offgrid/slide3/slide3__product3.webp'" alt="">
                        </div>
                    </div>
                </div>

                <div class="swiper-slide slide5">
                    <div class="slide5__left block">
                        <img :src="'./media/images/offgrid/slide5/slide5__photo.webp'" alt="">
                    </div>
                    <div class="slide5__right block">
                        <img :src="'./media/images/offgrid/slide5/slide5__logos.webp'" alt="">
                    </div>
                </div>

                <div class="swiper-slide slide4">
                    <div class="slide4__left block"
                        @click.prevent="openPopup($event, 'slider', discoverSlides, 'light', 0 )">
                        <img :src="'./media/images/offgrid/slide4/slide4__why.webp'" alt="">
                    </div>
                    <div class="slide4__right">
                        <div class="slide4__person-block block">
                            <img :src="'./media/images/offgrid/slide4/slide4__person.webp'" alt="">
                        </div>
                        <div class="slide4__discover-block block">
                            <img :src="'./media/images/offgrid/slide4/slide4__discover.webp'" alt="">
                        </div>
                    </div>
                </div>

                
            </div>
        </div>

        <div v-show="popup" class="popup" :class="[theme, navPosition]">
            <div class="popup__content">
                <span @click.prevent="closePopup(type)">
                        <CloseButton />
                    </span>
                <PopupSlider v-if="type === 'slider'" :images="media" />

                <PopupSliderSwitch v-if="type === 'slider-switch'" :images="media" />

                <PopupImageSwitch v-if="type === 'image-switch'" :images="media" />

                <PopupImage v-if="type === 'image'" :image="media" />

                <PopupIframe v-if="type === 'iframe'" section="offgrid" />

                <PopupVideo v-if="type === 'video'" :video="media" />
            </div>
        </div>

        <div v-show="popupIframe" class="popup light">
                <div class="popup__content">
                    <span @click.prevent="closePopupIframe(type)">
                        <CloseButton />
                    </span>

                    <PopupIframe section="offgrid" />
                </div>
            </div>

        <div class="animate-block">
            <div class="qf-card qf-front" />
            <div class="qf-card qf-back" />
        </div>

        <transition name="fade">
            <div v-show="screensaver" class="screensaver">
                <video class="screensaver__video" muted loop autoplay>
                    <source :src="'./media/video/showmaster-screensaver.mp4'" type="video/mp4">
                </video>
            </div>
        </transition>
    </div>
</template>
<script>
/* globals axios */

import Vue from 'vue';
import { gsap } from 'gsap';
import Flip from 'gsap/dist/Flip';
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

import CloseButton from './CloseButton.vue';
import PopupIframe from './PopupIframe.vue';
import PopupImage from './PopupImage.vue';
import PopupSlider from './PopupSlider.vue';
import PopupSliderSwitch from './PopupSliderSwitch.vue';
import PopupImageSwitch from './PopupImageSwitch.vue';
import PopupVideo from './PopupVideo.vue';

gsap.registerPlugin(Flip);

export default {
    components: {
        CloseButton,
        PopupIframe,
        PopupImage,
        PopupSlider,
        PopupSliderSwitch,
        PopupImageSwitch,
        PopupVideo,
    },

    data() {
        return {
            swiper: null,
            swiperEl: null,
            swiperSlides: null,
            video: null,
            currentSlide: null,

            //screensaver
            time: null,
            screensaver: false,
            screensaverVideo: null,

            // media
            systemSlides: [
                { image: './media/images/offgrid/systems/system-slide1.webp'},
                { image: './media/images/offgrid/systems/system-slide2.webp', click: './media/images/offgrid/new-features/new-features-slide1.webp' },
                { image: './media/images/offgrid/systems/system-slide3.webp' },
                { image: './media/images/offgrid/systems/system-slide4.webp' },
                { image: './media/images/offgrid/systems/system-slide5.webp', click: './media/images/offgrid/new-features/new-features-slide3.webp' },
            ],
            productSlides: [
                { image: './media/images/offgrid/devices/devices-slide1.webp' },
                { image: './media/images/offgrid/devices/devices-slide2.webp' },
                { image: './media/images/offgrid/devices/devices-slide3.webp' },
                { image: './media/images/offgrid/devices/devices-slide4.webp' }
            ],
            discoverSlides: [
                { image: './media/images/offgrid/discover/discover-slide1.webp' },
                { image: './media/images/offgrid/discover/discover-slide2.webp' },
                { image: './media/images/offgrid/discover/discover-slide3.webp' },
                { image: './media/images/offgrid/discover/discover-slide4.webp' },
                { image: './media/images/offgrid/discover/discover-slide5.webp' },
            ],
            vrmSlides: [
                './media/images/offgrid/monitoring/monitoring-vrm2.webp',
                './media/images/offgrid/monitoring/monitoring-vrm.webp', 
            ],

            // popup
            popup: false,
            popupIframe: false,
            theme: 'light',
            navPosition: 'right',
            media: null,
            type: null,
            slide: 1,

            // animate block
            clickBlock: null,
            startState: null,
            endState: null,
            animating: false,

            //status
            statusOnline: false
        };
    },

    mounted() {
        this.screensaverVideo = this.$el.querySelector('.screensaver__video');
        this.screensaverVideo.addEventListener('canplay', () => {
            this.screensaverVideo.play();
        });
        this.inactivityTime();

        this.swiperEl = this.$el.querySelector('.mySwiper');
        this.swiperSlides = this.$el.querySelectorAll('.swiper-slide');
        this.video = this.$el.querySelector('.slide2__video');

        this.initSwiper();

        this.statusOnline = navigator.onLine;

    },
    methods: {
        openPopup(event, type, media, theme, slide) {
            if (this.animating) {
                console.log('Cant open popup because animation is still going');
                return;
            }

            this.animating = true;
            this.popup = true;
            this.type = type;
            this.media = media;
            this.theme = theme;
            this.slide = slide;

            // if (type === 'video') {
            //     this.video.pause();
            // }

            this.endState = Flip.getState('.animate-block');

            this.clickBlock = event.currentTarget;
            const block = this.clickBlock.getBoundingClientRect();
            const blockWidth = `${block.width}px`;
            const blockHeight = `${block.height}px`;
            const blockTop = `${block.top}px`;
            const blockLeft = `${block.left}px`;

            gsap.set('.animate-block', {
                width: blockWidth, height: blockHeight, top: blockTop, left: blockLeft,
            });
            this.startState = Flip.getState('.animate-block');
            gsap.set(this.clickBlock, { pointerEvents: 'none' });
            gsap.set('.animate-block', {
                transformStyle: 'preserve-3d',
                transformPerspective: 1000,
            });
            gsap.set('.qf-card', {
                transformStyle: 'preserve-3d',
                transformOrigin: '50% 50%',
            });
            gsap.set('.qf-back', {
                rotationY: 180, rotationZ: 180,
            });

            const tl = gsap.timeline();
            tl.to(this.clickBlock, { opacity: 0, duration: 0.2 });
            tl.to('.animate-block', { opacity: 1, duration: 0.2 }, '=');
            tl.to('.qf-card', { rotationX: '+=180', duration: 0.3 });

            tl.to('.animate-block', {
                top: 0,
                height: '100%',
                duration: 0.4,
                ease: 'power4.out',
                onComplete: (slide) => {
                    Flip.to(this.endState, {
                        duration: 0.4,
                        ease: 'power4.out',
                        onComplete: (slide) => {

                        },
                    });
                    this.popupSystems = true;
                    EventBus.$emit('open-popup', this.slide);
                },

            });
        },

        closePopup(type) {
            if (type === 'video') {
                this.$el.querySelector('.popup__video').pause();
                this.$el.querySelector('.popup__video').currentTime = 0;
                // this.video.play();
            }

            gsap.to('.popup__content', {
                opacity: 0,
                duration: 0.3,
            });
            this.popup = false;
            gsap.set(this.clickBlock, { pointerEvents: 'auto' });

            const block = this.clickBlock.getBoundingClientRect();
            const blockWidth = `${block.width}px`;
            const blockLeft = `${block.left}px`;

            const tl = gsap.timeline();

            tl.to('.animate-block', {
                left: blockLeft,
                width: blockWidth,
                x: 0,
                y: 0,
                ease: 'power4.out',
                onComplete: () => {
                    Flip.to(this.startState, {
                        duration: 0.4,
                        ease: 'power4.out',
                        onComplete: () => {
                            gsap.set('.block', { opacity: 1 });
                            gsap.to('.animate-block', {
                                opacity: 0,
                                duration: 0.2,
                                onComplete: () => {
                                    gsap.set('.animate-block', { clearProps: 'all' });
                                    gsap.set('.qf-card', { clearProps: 'all' });
                                    this.animating = false;
                                },
                            });
                        },
                    });
                    gsap.to('.qf-card', { rotationX: '+=180', duration: 0.3 });
                },
            });
        },

        initSwiper() {
            if (this.swiper) {
                return;
            }

            this.swiper = new Swiper(this.swiperEl, {
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 0,
                touchRatio: 2,
                speed: 600,
            });

            this.currentSlide = this.swiper.realIndex;

            this.swiper.on('activeIndexChange', (swiper) => {
                // if (this.swiper.realIndex === 0) {
                //     this.reInitSwiper();
                // }
                
                this.currentSlide = swiper.realIndex;
            });
        },

        slideTo(slideIndex) {
            this.swiper.slideToLoop(slideIndex);
        },

        destroySwiper() {
            if (!this.swiper) {
                return;
            }

            this.swiper.destroy();
            this.swiper = null;
        },

        reInitSwiper() {
            this.destroySwiper();
            this.initSwiper();
        },

        inactivityTime() {
            document.addEventListener('touchstart', this.disableScreensaver);
            document.addEventListener('mousemove', this.disableScreensaver);
            document.addEventListener('keydown', this.disableScreensaver);
            document.addEventListener('scroll', this.disableScreensaver);
        },

        disableScreensaver() {
            this.screensaver = false;
            this.screensaverVideo.pause();
            clearTimeout(this.time);
            this.time = setTimeout(this.showScreensaver, 180000); // 1000 milliseconds = 1 second
        },

        showScreensaver() {
            this.screensaver = true;
            this.screensaverVideo.play();
        }
    },
};
</script>
